.home-page {
    min-height: calc(100vh - 120px);
    position: relative;
    max-width: 1100px;
    padding: 20px 20px;
    margin: 0 auto;
    color: var(--main-color);
}

.home-page__header {
    padding: 80px 0;
    font-weight: 400;
    text-align: center;
    font-size: 6rem !important;
}

.home-page__header--bold {
    font-weight: var(--heavy-font-weight);
}

.home-page__boxes {
    margin-top: 52px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
}

.home-page__box {
    max-width: 400px;
    padding: 16px 12px;
    border-radius: 5px;
    background-color: var(--lighter-main-color);
    text-align: center;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.home-page__box--transparent {
    background: none;
}

.home-page__box-header {
    font-weight: var(--extra-bold-font-weight);
    font-size: 3rem !important;
}

.home-page__box-text {
    margin: 10px 0;
}

.home-page__circle {
    position: absolute;
    overflow: hidden;
    height: 90vh;
    width: 90vh;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.home-page__circle-image {
    position: absolute;
    width: 60%;
    height: auto;
    bottom: 0;
    left: 50%;
    z-index: 1;
    transform: translateX(-70%);
}

@media (max-width: 1100px) {
    .home-page__circle-image {
        width: 50%;
    }
}

@media (max-width: 900px) {
    .home-page__header {
        padding: 40px 0;
        font-size: 4rem !important;
    }
    .home-page__boxes {
        flex-direction: column-reverse;
        align-items: center;
        gap: 40px;
        margin-top: 20px;
    }
    .home-page__circle-image {
        display: none;
    }
    .home-page__circle {
        height: 65vh;
        width: 65vh;
    }
}

@media (max-width: 800px) {
   .home-page {
    min-height: calc(100vh - 140px);
   }
}

@media (max-width: 600px) {
    .home-page {
     min-height: calc(100vh - 120px);
    }
 }

