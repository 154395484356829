.answer-popup-wrapper {
  position: relative;
  z-index: 9999;
}


.answer-popup__surface {
    position: fixed;
    z-index: -1;
    top:0;
    left:0;
    left: -100vh;
    width: 150vh;
    height: 100vh;
    cursor:default;
  }
  
  .answer-popup {
    position: absolute;
    transform: translate(-100%, -100%);
    top: 60px;
    left: -15px;
    min-width: 300px;
    max-width: 600px;
    padding: 10px;
    border: 2px solid var(--secondary-color);
    border-radius: 10px;
    background-color: #fff;
    margin-right: 10px;
    text-align: left;
    color: var(--main-color);
  }

  .answer-popup__image {
    width: 100%;
    height: auto;
    max-height: 400px;
  }
  .answer-popup__header {
    text-align: center;
    font-size: 25px;
    margin-bottom: 5px;
    letter-spacing: 1px;
    font-weight: 500;
  }
  
  .answer-popup__text {
    margin-bottom: 5px;
  }

  @media (max-width: 900px) {
    .answer-popup__surface {
      background-color: rgba(2, 67, 110, .7);
  
    }

    .answer-popup-wrapper {
      position: fixed;
    }

    .answer-popup {
      position: fixed;
      left: 110px;
      top: 50%;
      transform: translate(-100%, -50%);
      max-width: 90vw;
    }
  }