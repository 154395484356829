.certificate-modal {
    background-color: #fff;
}

.certificate-modal {
    color: var(--main-color);
    width: 900px;
    max-width: 90vw;
}

.certificate-modal__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 2rem;
}

.certificate-modal__wrapper--disabled {
    color: #888;
}

.certificate-modal__header {
    font-size: 3rem;
    color: var(--main-color);
    text-transform: uppercase;
    text-align: center;
}

.certificate-modal__disabled-message {
    text-transform: uppercase;
    background-color: var(--secondary-color);
    color: var(--main-color);
    font-size: 2.5rem;
    display: inline-block;
    padding: 1rem;
    border-radius: 5px;
    font-weight: 700;
    margin-top: 2rem;
}

.certificate-modal__content-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5rem;
    margin: 2rem 0;
}

.certificate-modal__cert-image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    box-shadow: 0px 0px 10px -5px rgba(66, 68, 90, 1);
}

.certificate-modal__wrapper--disabled .certificate-modal__cert-image {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
}

.certificate-modal__cert-form-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.certificate-modal__cert-form {
    width: 100%;
}

.certificate-modal__input-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 8fr 2fr;
    margin: 1rem 0;
}

.certificate-modal__input {
    width: 100%;
    padding: 5px 10px;
    background-color: var(--light-main-color);
    color: var(--main-color);
    border: none;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    outline: none;
}

.certificate-modal__wrapper--disabled .certificate-modal__input {
    background-color: #ccc;
    pointer-events: none;
}

.certificate-modal__wrapper--disabled .certificate-modal__input::placeholder {
    color: #fff;
}

.certificate-modal__input::placeholder {
    color: var(--main-color);
}

.certificate-modal__input-button {
    width: 100%;
    padding: 10px 0;
    background-color: var(--main-color);
    color: var(--secondary-color);
    border: none;
    outline: none;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
}

.certificate-modal__wrapper--disabled .certificate-modal__input-button,
.certificate-modal__input-button:disabled {
    background-color: #666;
    color: #fff;
    pointer-events: none;
}

.certificate-modal__warning {
    display: flex;
    gap: 1rem;
}

.certificate-modal__download-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.certificate-modal__download-button {
    padding: 10px;
    background-color: var(--main-color);
    color: var(--secondary-color);
    border: none;
    outline: none;
    border-radius: 5px;
    font-size: 1.6rem;
    font-weight: 700;
    cursor: pointer;
}

.certificate-modal__wrapper--disabled .certificate-modal__download-button,
.certificate-modal__download-button:disabled {
    background-color: #666;
    color: #fff;
    pointer-events: none;
}
