.expression-translation-activity__expression-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.expression-translation-activity__image-wrapper {
    width: 220px;
    height: 150px;
    background-position: center;
    background-size: cover;
    border: 2px solid rgb(218, 218, 218);
    border-radius: 5px;
}

.expression-translation-activity__expression {
    font-size: 5.4rem;
    margin-left: 40px;
}

.expression-translation-activity__translations-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 60px;
}

.expression-translation-activity__translation {
    border: 2px solid var(--secondary-color);
    padding: 40px 10px;
    border-radius: 5px;
    width: 25%;
    margin: 0 15px;
}

.expression-translation-activity__translation:hover {
    cursor: pointer;
}

.expression-translation-activity__translation-text {
    text-align: center;
    font-size: 2rem;
}

@media (max-width: 600px) {
    .expression-translation-activity__expression {
        font-size: 4.6rem;
        margin-left: 20px;
    }

    .expression-translation-activity__image-wrapper {
        width: 200px;
        height: 130px;
    }


    .expression-translation-activity__translation {
        padding: 30px 10px;
        margin: 0 5px;
        width: 30%;
    }

    .expression-translation-activity__translations-wrapper {
        margin-top: 40px;
    }
    
}

@media (max-width: 500px) {

    .expression-translation-activity__expression-wrapper {
        flex-direction: column-reverse;
    }

    .expression-translation-activity__expression {
        font-size: 4rem;
        margin-left: 0;
        margin-bottom: 10px;
    }

    .expression-translation-activity__translations-wrapper {
        flex-direction: column;
        margin-top: 20px;
    }

    .expression-translation-activity__translation {

        padding: 15px 10px;
        width: 100%;
        max-width: 200px;
        margin: 5px 0;
    }

}