.admin-page {
    display: grid;
    grid-template-columns: auto 1fr;
    min-height: 100vh;
}

.admin-page__main {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}