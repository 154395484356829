.mail-setting__wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.mail-setting__language {
    font-size: 2rem;
    font-weight: 700;
    display: block;
    margin: 10px auto;
}

.mail-setting__input-wrapper {
    display: flex;
    flex-direction: column;
}

.mail-setting__label {
    font-size: 1.3rem;
    margin-bottom: 3px;
    font-weight: 700;
}

.mail-setting__input {
    border: 2px solid var(--main-color);
    border-radius: 5px;
    padding: 5px;
}

.mail-setting__checkbox {
    width: 20px;
}