.task-activity {
    align-items: center;
    justify-content: space-between;
    margin: 15px 0;
}

.task-activity__example-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
}

.task-activity__textarea {
    margin: 10px 0;
    width: 100%;
    height: 200px;
    outline: none;
    resize: none;
    padding: 5px;
}

.task-activity_text {
    text-align: center;
    font-size: 2.3rem;
    margin: 5px auto;
}

.task-activity_text--bold {
    font-weight: 700;
}

.task-activity_text--margin {
    margin: 30px 0;
}

.task-activity__title {
    font-size: 2.7rem;
    margin: 10px 0;
    text-align: center;
}

.task-activity__example-text {
    width: 150px;
    padding: 10px;
    border-radius: 5px;
}

.task-activity__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
}

.task-activity__example-expander {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    font-weight: 700;
    gap: 5px;
    margin-left: 10px;
}

.task-activity__example-icon {
    font-size: 2.4rem;
}

.task-activity__button {
    margin: 0;
    margin-top: 20px;
}

.task-activity__button--skip {
    background-color: #f5f5f5;
    color: #000;
}

.task-activity__button:disabled {
    background-color: #f5f5f5;
    color: rgb(110, 110, 110);
    pointer-events: none;
}

.task-activity__textarea-wrapper {
    position: relative;
}

.task-activity__checked-icon-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    color: #64FF84;
    font-weight: 700;
    gap: 10px;
}