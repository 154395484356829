.mail-settings-page__type-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
}

.mail-settings-page__settings-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 50px;
    max-width: 80%;
    padding: 20px;
    align-items: center;
    justify-content: center;
}

