.main-button {
    border: none;
    border-radius: 5px;
    padding: 7px 20px;
    font-family: 'Roboto';
    font-weight: 700;
    font-size: 1.8rem;
    color: #fff;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    background-color: var(--main-color);

  }
  