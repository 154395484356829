.change-password-home-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh;
  background-color: rgba(30, 45, 106, 0.3);
  backdrop-filter: blur(3px);
  z-index: 101;
  display: none;
}

.change-password-home-modal.change-password-home-modal--active {
    display: block;
}

.change-password-home-modal-box {
    position: fixed;
    top: 50%;
    left: 50%;
    max-height: 100%;
    overflow-y: scroll;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 7px;
    max-height: 90vh;
}


.change-password-home-modal__form {
    max-width: 100%;
    width: 400px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 20px 20px 20px;
    border-radius: 5px;
  }

  .change-password-home-modal__main-form-content {
    width: 100%;
  }

  .change-password-home-modal__buttons-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 0 10px;
  }
  
  .change-password-home-modal__closing-button {
    background-color: transparent;
    border: none;
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: var(--main-color);
    border: 2px solid var(--main-color);
    cursor: pointer;
} 

.change-password-home-modal__info-button {
  background-color: transparent;
  border: none;
  font-size: 1.8rem;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--light-main-color);;
  border: 2px solid var(--light-main-color);;
  cursor: pointer;
}

  .change-password-home-modal__form-title {
    font-size: 4rem;
    font-weight: 700;
    padding-bottom: 20px;
    text-transform: uppercase;
    color: var(--main-color);;
    font-family: 'roboto';
  }

  .change-password-home-modal__form-slogan {
    text-align: center;
    line-height: 120%;
    color: var(--main-color);;
    margin-bottom: 30px;
  }
  
  .change-password-home-modal__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;
  }
  
  .change-password-home-modal__input-label {
    color: var(--main-color);
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 120%;
    margin-bottom: 5px;
  }
  
  .change-password-home-modal__input {
    font-size: 1.6rem;
    padding: 10px 5px;
    background-color: #f7f7f7;
    border: 1px solid var(--main-color);
    border-radius: 10px;
    width: 100%;
    height: 40px;
  }
  
  .change-password-home-modal__input-desc {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .change-password-home-modal__input:focus {
    outline: none;
  }
  
  .change-password-home-modal__alert {
    margin-top: 10px;
    color: #f00;
    font-size: 1.6rem;
    font-weight: 600;
  }
  
  .change-password-home-modal__password-wrapper {
    position: relative;
    width: 100%;
  }
  
  .change-password-home-modal__password-icon-button {
    border: none;
    background: transparent;
    font-size: 2rem;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--main-color)
  }

  .change-password-home-modal__info-text-wrapper {
    position: relative;
  }

  .change-password-home-modal__info-button:hover .change-password-home-modal__info-text{
    display: block;
}

.change-password-home-modal__info-text {
  display: none;
  position: absolute;
  left: 50%;
  top: 55px;
  z-index: 99;
  transform: translateY(-50%);
  right: 100%;
  margin-right: 15px;
  background-color: var(--white-color);
  width: 300px;
  max-width: 80vw;
  padding: 10px 20px;
  border-radius: 5px;
}

.change-password-home-modal__info-text {
    font-family: 'Roboto';
    text-transform: none;
    color: #000;
}

.change-password-home-modal__span-block {
    display: block;
}


@media (max-width: 600px) {
    .change-password-home-modal-box {
        width: 98%;
    }
}
