.tutorial-modal {
    width: 800px;
    max-width: 98vw;
}

.tutorial-modal__content {
    max-width: 900px;
    margin: 0 auto;
    padding: 0 15px;
}

.tutorial-modal__title {
    color: var(--main-color);
    text-transform: uppercase;
    font-size: 3.4rem;
    text-align: center;
    margin: -30px 0 15px;
}

.tutorial-modal__tutorial-point {    
    margin: 0 10px;
}

.tutorial-modal__tutorial-point-text {
    font-family: 'Roboto', sans-serif;
    color: var(--main-color);
    margin-bottom: 20px;
    text-align: center;
}

.tutorial-modal__tutorial-point-title {
    color: var(--main-color);
    font-size: 3rem;
    text-align: center;
    margin-bottom: 10px;
}

.tutorial-modal__ordered-list {
    font-family: 'Roboto', sans-serif;
    color: var(--main-color);
    margin-top: 10px;
}

.tutorial-modal__ordered-list-element {
    margin: 5px 0;
}

.tutorial-modal__tutorial-img-wrapper {
    text-align: center;
}

.tutorial-page__img {
    transform: scale(0.8);
    max-width: 100%;
}

.tutorial-modal__nested-list {
    list-style: none;
}

.tutorial-modal__nested-list-element {
    margin: 5px 0;
}

.tutorial-modal__prev-point-btn, .tutorial-modal__next-point-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: var(--secondary-color);
    font-size: 3.6rem;
}

.tutorial-modal__prev-point-btn {
    left: 10px;
}

.tutorial-modal__prev-point-btn:disabled,.tutorial-modal__next-point-btn:disabled {
    display: none;
}

.tutorial-modal__next-point-btn {
    right: 10px;
}

.tutorial-modal__squares-wrapper {
    /* position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%); */
    width: 100%;
    display: flex;
    margin: 0 auto;
    padding: 30px 0 0;
    align-items: center;
    justify-content: center;
}

.tutorial-modal__square {
    width: 22px;
    height: 32px;
    background-color: rgb(211, 211, 211);
    border-radius: 5px;
    margin: 0 2px;
    cursor: pointer;
}

.tutorial-modal__square--active {
    width: 24px;
    height: 38px;
    background-color: var(--secondary-color);
}

.tutorial-modal__square--completed {
    background-color: var(--green-color);
}

@media (max-width: 768px) {
    .tutorial-page__img {
        transform: scale(1);
    }
}