.change-password-modal__title {
    text-align: center;
    margin-bottom: 10px;
    text-transform: uppercase;
    color: var(--main-color);
}

.change-password-modal__input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }

  .change-password-modal__input-label {
    color: var(--main-color);
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 5px;
  }
  
  .change-password-modal__input {
    font-size: 1.6rem;
    padding: 5px 3px;
    border: none;
    border: 2px solid var(--main-color);
    border-radius: 3px;
    width: 250px;
    background-color: transparent;
  }
  
  .change-password-modal__input-desc {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 15px;
  }
  
  .change-password-modal__input:focus {
    outline: none;
  }
  
  .change-password-modal__alert {
    color: #f00;
    font-size: 1.6rem;
    font-weight: 600;
  }
  
  .change-password-modal__password-wrapper {
    position: relative;
  }
  
  .change-password-modal__password-icon-button {
    border: none;
    background: transparent;
    font-size: 2rem;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--main-color)
  }