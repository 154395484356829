.basic-progress-bar-wrapper {
    position: relative;
    display: flex;
    margin: 10px auto;
    justify-content: center;
    align-items: center;
}

.basic-progress-bar-wrapper .progress {
    width: 250px;

    height: 15px;
    margin: 0 10px;
    overflow: visible;
    border: 1px solid var(--green-color);
    border-radius: 5px;
    overflow: hidden;
}

.basic-progress-bar-wrapper .progress-bar {
    position: relative;
    overflow: visible;
    background-color: var(--green-color);
    z-index: 1;
    height: 100%;
}

.basic-progress-label {
    color: var(--green-color);
    font-weight: 600;
    font-size: 1.8rem;
}

.basic-progress-label--translated-left {
    transform: translate(-165%, -50%);
}

@media (max-width: 530px) {
    .basic-progress {
        width: 250px;
    }
}

@media (max-width: 370px) {
    .basic-progress {
        width: 200px;
    }
}
