.word-pair-rate__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.word-pair-rate__text {
    color: #888;
    font-size: 1.6rem;
    margin-right: 5px;
}

.word-pair-rate__rate {
    width: 25px;
    height: 25px;
    margin-left: 3px;
    transition: transform .3s ease;
    fill: #ccc;
    margin-right: 2px;
}
.word-pair-rate__rate:hover {
    cursor: pointer;
    transform: scale(1.1);
}

.word-pair-rate__rate--up-active {
    fill: #00ff00;
}

.word-pair-rate__rate--down-active {
    fill: #ff0000;
}