.task-lesson-modal-hero {
    background: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
}

.task-lesson-modal {
    padding: 20px;
    background: #fff;
    border: 1px solid #000;
    border-radius: 5px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    max-height: 95%;
    overflow-y: scroll;
}

.task-lesson-modal-input {
    display: block;
    width: 300px;
}


.task-lesson-modal-img {
    width: 300px;
    height: auto;
    display: block;
}

.task-lesson-modal-label {
    display: block;
    margin-top: 10px;
}


.task-lesson-modal-button {
    display: block;
    padding: 5px 10px;
    margin: 5px auto;
    border: none;
    background-color: rgb(0, 128, 17);
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
    transition: all .3s ease;
}

