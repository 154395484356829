.tutorial-stats-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tutorial-stats-page__table {
    width: 300px;
    padding: 0;
}

.tutorial-stats-page__table-header {
    width: 100%;
    background: #000;
    color: #fff;
    padding: 10px;
}

.tutorial-stats-page__table-row {
    width: 100%;
    text-align: center;
}

.tutorial-stats-page__table-cell {
    padding: 10px;
} 

.tutorial-stats-page__table-row:nth-of-type(odd) {
    background: #eee;
}
