.lesson-images-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lesson-images-page__image {
    width: 200px;
    cursor: pointer;
    display: block;
    border: 5px solid transparent;

}

.lesson-images-page__image--selected {
    border: 5px solid red;
}



.lesson-images-page__button {
    border: none;
    border-radius: 1000px;
    background-color: rgb(0, 156, 0);
    font-size: 2rem;
    font-weight: 600;
    padding: 10px 20px;
    color: #fff;
    display: block;
    margin: 0 auto;
}