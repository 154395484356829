.task-chat-gpt-query {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.task-chat-gpt-query__textarea {
    margin-top: 30px;
    padding: 10px;
    resize: none;
    letter-spacing: .5px;
    width: 600px;
    height: 300px;
}

.task-chat-gpt-query__button {
    margin-top: 10px;
}