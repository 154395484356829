.rate-poll {
    color: var(--main-color);
    padding: 0 30px;
}
.rate-poll__heading {
    text-align: center;
    font-size: 3.5rem !important;
    letter-spacing: 0.3px;
}
.rate-poll__text {
    text-align: center;
    margin: 30px 0;
    letter-spacing: 0.2px;
}

.rate-poll__button-transparent {
    background: transparent;
    border: 2px solid var(--main-color);
    color: var(--main-color);
}

.rate-poll__question-list-item {
    margin: 20px 0;
    display: block;
    font-size: 2.2rem;
    text-align: center;
    font-weight: 700;
}
.rate-poll__radio-wrapper {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rate-poll__radio-item {
    border-width: 2px 0 2px 2px;
    border-style: solid;
    border-color: var(--secondary-color);
    padding: 10px 30px;
    cursor: pointer;
    font-size: 2rem;
    font-weight: 700;
    color: var(--main-color);
}

.rate-poll__radio-item:last-child {
    border-right-width: 2px;
}

.rate-poll__radio-item--checked {
    background-color: var(--secondary-color);
}

.rate-poll__buttons-wrapper {
    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 30px;
}

.rate-poll__button {
    margin: 0;
}

.rate-poll__subheading {
    margin: 10px 0;
}

.rate-poll__textarea {
    width: 100%;
    height: 200px;
    background-color: var(--white-color);
    padding: 5px;
    border: 2px solid var(--secondary-color);
    outline: none;
    margin-bottom: 10px;
    resize: none;
}

@media (max-width: 600px) {
    .rate-poll {
        padding: 0;
    }
}
@media (max-width: 400px) {
    .rate-poll__radio-item {
        padding: 10px 25px;
    }
}
