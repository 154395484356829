.mailbox-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background: #fff;
}

.mailbox-conetent__setter {
    width: 400px;
    height: 1px;
}

.mailbox-wrapper__top-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
}

.mailbox-table {
    width: 100%;
    padding: 0 30px 30px 30px;
    height: 85vh;
}

.mailbox-table__style {
    width: 100%;
    max-height: 85vh;
    border-radius: 8px;
    background: #fff;
    overflow-y: auto;
}

.consultant-mailbox-pagination-wrapper {
    position: relative;
    display: flex;
    width: 90%;
    margin: 10px auto;
    justify-content: center;
    align-items: center;
}

.consultant-mailbox-pagination-count {
    position: absolute;
    right: 0;
    font-weight: 600;
}

.consultant-mailbox-button {
    margin: 0 20px;
    background-color: #777;
    color: #fff;
    font-weight: 700;
    padding: 5px 10px;
    border: none;
    outline: none;
    border-radius: 5px;
}

.status {
    padding: 3px 7px;
    gap: 11px;
    border-radius: 8px;
}

.status-new {
    color: #fff;
    background: #fe6a2a;
}
.status-completed {
    color: var(--main-color);
    background: #a0df9f;
}
.status-new_in_conversation {
    background: #fccd43;
    color: var(--main-color);
}

.status-seen {
    background: #afafaf;
    color: #ffffff;
}

.mailbox-table table {
    width: 100%;
    border-collapse: collapse;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #777;
}

::-webkit-scrollbar-thumb:hover {
    background: #777;
}

::-webkit-scrollbar-thumb:active {
    background: #777;
}

.mailbox-table tbody {
    overflow: auto;
    width: 100%;
}

.mailbox-table thead tr {
    width: 100%;
    text-align: left !important;
}
.mailbox-table th {
    text-align: left !important;
}

.mailbox-table table tbody tr {
    color: var(--main-color);
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: #fff;
    border-bottom: 1px solid var(--light-main-color) !important;
}

.mailbox-table tbody tr:hover {
    border-bottom: 1px solid var(--light-main-color);
    background: var(--light-main-color);
    color: white;
}

.mailbox-table td {
    cursor: pointer;
    white-space: nowrap; /* Zapobiega zawijaniu tekstu */
    overflow: hidden; /* Ukrywa tekst poza obszarem komórki */
    text-overflow: ellipsis;
}

.mailbox-table td {
    padding: 10px 20px;
}

.mailbox-table th {
    padding: 5px;
}

.mailbox-table th * {
    color: #fff !important;
    border: none;
}

.mailbox-table .css-z5e0z9-MuiFormLabel-root-MuiInputLabel-root {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}

.mailbox-table .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 15px;
    font-weight: 700;
}

.mailbox-table .css-953pxc-MuiInputBase-root-MuiInput-root::before {
    display: none !important;
}

.mailbox-table .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 15px;
  font-weight: 700;
}

.mailbox-table .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 15px;
  font-weight: 700;
}

.mailbox-table .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 15px;
}


.mailbox-table
    .css-1h51icj-MuiAutocomplete-root
    .MuiInput-root.MuiInputBase-sizeSmall
    .MuiInput-input {
    color: #fff;
    font-weight: 700;
}
.mailbox-table th {
    text-align: left;
    color: #fff;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.mailbox-table .mailbox-thead {
    border: 1px solid var(--light-main-color2);
    background: #777;
}

.mailbox-wrapper__box__element input:hover,
.mailbox-wrapper__box__element input:focus {
    outline: none;
}

.mailbox-button__back {
    width: 200px;
}

.mailbox-title {
    text-align: center;

    color: var(--main-color);
    height: fit-content;
    font-family: Roboto;
    font-size: 25px;
    font-style: normal;
    font-weight: 900;
    text-transform: uppercase;
    padding: 20px 10px;
}

.mailbox-wrapper__box__element {
    width: 90%;
    height: calc(100vh - 70px);
    border: 1px solid #eee;
}

.mailbox-mail__end {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: right;
}

.mailbox-mail__topic {
    display: flex;
    align-items: center;
    color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mailbox-mail__text {
    color: #fff;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mailbox-wrapper__box__element input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #fff; /* Brązowy kolor obwódki */
    background-color: #fff; /* Białe tło */
    outline: none;
    cursor: pointer;
}

/* Ukrywanie oryginalnego znacznika (checkmark) */
.mailbox-wrapper__box__element input[type='checkbox']::before {
    content: '\2713'; /* Kod znaku checkmark */
    display: none; /* Ukrywamy oryginalny checkmark */
    text-align: center;
    font-size: 14px;
    color: #999; /* Brązowy kolor checkmark */
    line-height: 16px;
}

/* Styl dla zaznaczonego checkboxa */
.mailbox-wrapper__box__element input[type='checkbox']:checked {
    background-color: #fff; /* Białe tło (można zostawić bez zmian) */
    border: 2px solid #fff; /* Brązowy kolor obwódki (można zostawić bez zmian) */
}

/* Styl dla zaznaczonego checkboxa - pokazujemy customowy checkmark */
.mailbox-wrapper__box__element input[type='checkbox']:checked::before {
    display: block;
}


.mailbox-sender {
    width: 15%;
}

.mailbox-wrapper__elements {
    height: calc(100% - 280px);
    overflow: auto;
}

.mailbox-wrapper__typing {
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 30px;
    position: absolute;
    bottom: 0;
    background: #eee;
    border-top: 1px solid #ddd;
}

.mailbox-texarea__typing {
    resize: none;
    width: 100%;
    border: none;
    padding: 10px;
    height: 180px;
    color: var(--main-color);
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-right: 1px solid #ddd;
}

.mailbox-texarea__typing:focus {
    outline: none;
}

.mailbox-button__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
    background-color: #fff;

}

.mailbox-button__utils {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 180px;
}

.mailbox-button__typing {
    padding: 10px 30px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border-radius: 8px;
    background: var(--main-color);
    border: none;
    cursor: pointer;
    height: fit-content;

    color: #fff;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.mailbox-icon {
    width: 5vw;
}

.mailbox-element {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    /* border-bottom: 1px solid var(--light-main-color2); */

    color: var(--main-color);
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: space-between;
}

.mailbox-button__back {
    cursor: pointer;
}

.mailbox-wrapper__box__element__style {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: relative;
}

.mailbox-wrapper__top_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 20px;
    background: #777;
}

.mailbox-message__image-wrapper {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.mailbox-message__image {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: auto;
}

.mailbox-button__util-button {
    background: none;
    color: var(--main-color);
    border: none;
    outline: none;
    font-size: 20px;
}

.mailbox-message__word {
    padding: 10px;
    border: 1px solid #555;
    width: fit-content;
    margin: 10px auto;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.7);
}


@media (max-width: 768px) {
    .mailbox-wrapper__box__element {
        width: 100%;
    }

    .mailbox-mail__topic {
        font-size: 14px;
    }

    .mailbox-mail__text {
        font-size: 14px;
    }

    
}