.activity-stats-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.activity-stats-page__user-score {
    display: flex;
    font-size: 24px;
    font-size: bold;
    color: #222;
    background: #ddd;
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
}

.activity-stats-page__table {
    width: 300px;
    padding: 0;
}

.activity-stats-page__table-header {
    width: 100%;
    background: #000;
    color: #fff;
    padding: 10px;
}

.activity-stats-page__table-row {
    width: 100%;
    text-align: center;
}

.activity-stats-page__table-cell {
    padding: 10px;
} 

.activity-stats-page__table-row:nth-of-type(odd) {
    background: #eee;
}
