.user-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.user-table__row:nth-child(2n) {
    background: #ddd;
    
}

.user-table .rc-table-row {
    height: 50px;
}

.user-table-pagination-wrapper {
    display: flex;
    width: 100%;
    max-width: 1100px;
    margin: 10px auto;
    justify-content: center;
}

.user-table-pagination-button {
    margin: 0 20px;
}

.user-table-button-std {
    border: none;
    border-radius: 1000px;
    background-color: rgb(0, 156, 0);
    font-size: 1.5rem;
    padding: 5px 10px;
    color: #fff;
}

.user-table-button-red {
    border: none;
    border-radius: 1000px;
    background-color: rgb(156, 0, 0);
    font-size: 1.5rem;
    padding: 5px 10px;
    color: #fff;
}

.user-table-button-std:disabled {
    pointer-events: none;
}

.user-table__buttons-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    flex-direction: column;
}

.user-table__edit-button {
    border: none;
    margin: 5px 0;
    background: rgb(0, 102, 255);
    padding: 5px 10px;
    font-size: 1.3rem;
    color: #fff;
    font-weight: 600;
}

.user-table__edit-button--red {
    background: #b60000;
}

.user-table__edit-button--green {
    background: #008015;
}
