.question-button-wrapper {
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 103;
    transition: all 0.5s ease;
    right: -100%;
}

.question-button-wrapper--translated {
    transform: translateX(-100%);
}

.question-button {
    width: 100px;
    height: 100px;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.question-button__question-img {
    width: 100%;
    height: 100%;
}

.question-popup__surface {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.question-popup {
    max-width: 400px;
    padding: 10px;
    border: 2px solid #95c34b;
    border-radius: 10px;
    background-color: #fff;
    margin-right: 10px;
    text-align: center;
    color: var(--main-color);
}
.question-popup__header {
    font-size: 20px;
    margin-bottom: 5px;
}

.question-popup__text {
    margin-bottom: 5px;
}

.question-popup__form {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
}

.question-popup__input {
    border: 2px solid var(--main-color);
    border-radius: 5px;
    outline: none;
    padding: 0 10px;
    height: 30px;
    max-width: 100%;
}

.question-popup__send-button {
    background: #95c34b;
}

@media (max-width: 768px) {
    .question-button-wrapper {
        flex-direction: column;
        margin-bottom: 40px;
    }

    .question-button {
        width: 80px;
        height: 80px;
    }
    .question-popup {
        max-width: 90vw;
    }

    .question-popup__form {
        grid-template-columns: auto;
        grid-gap: 10px;
    }
}
