.activity-feedback {
    margin: 80px auto 0;
    background-color: var(--green-color);
    padding: 5px 20px;
    width: fit-content;
    border-radius: 5px;

}

.activity-feedback--incorrect-answer {
    margin: 0 auto 0;
    background-color: var(--red-color);
}

.activity-feedback__text {
    font-size: 2.4rem;
    font-weight: 700;
    color: #fff;
}

.activity-feedback__modal {
    background-color: transparent;

}

.activity-feedback__modal .main-modal-box {
    padding: 10px;
    border: 2px solid  var(--red-color);
    background-color: #fff;

} 

.activity-feedback__desc-text {
    text-align: center;
    font-size: 1.6rem;
    margin: 10px 0;
    font-weight: 600;
    color: var(--main-color);

}

.activity-feedback__answer {
    text-align: center;
    font-size: 2rem;
    margin: 10px 0;
}