.link-expression-activity__main-content {
    display: grid;  
    align-items: stretch;
    justify-content: space-around;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 50px;
}


.link-expression-activity__expressions-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 15px;
    justify-items: center;
}



.link-expression-activity__expressions-wrapper {
   margin: 0 auto;
   width: 60%;
}

.link-expression-activity__expression {
    border: 2px solid #ccc;
    padding: 10px 10px;
    border-radius: 5px;
    width: 100%;
}

.link-expression-activity__expression:hover{
    cursor: pointer;
}

.link-expression-activity__expression--blured {
    opacity: 0;
}

.link-expression-activity__expression--wrong {
    border-color: var(--red-color);
}
.link-expression-activity__expression--correct {
    background-color: var(--green-color);
}

.link-expression-activity__expression--active {
    border-color: var(--secondary-color);
}

.link-expression-activity__expression-text {
    text-align: center;
    font-size: 2rem;
}

.link-expression-activity__feedback {
    margin-top: 50px;
    margin: 100px auto 0;
    background-color: var(--red-color);
    padding: 5px 20px;
    width: fit-content;
    border-radius: 5px;
    font-size: 2.6rem;
    font-weight: 700;
    color: #fff;
}