.coloured-speech-bubble {
    max-width: 450px;
    border-radius: 5px;
    padding: 20px;
    background-color: #fff;
    color: var(--main-color);
    position: relative;
    }
  

    