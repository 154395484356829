.platform-main-page {
  min-height: 100vh;
}

.platform-main-page__background {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/en.webp");
  background-position: center;
  background-size: cover;
  padding: 90px 0 40px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.platform-main-page__background--en {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/en.webp");
}

.platform-main-page__background--es {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/es.webp");
}

.platform-main-page__background--de {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/de.webp");
}

.platform-main-page__background--it {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/it.webp");
}

.platform-main-page__background--nl {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/nl.webp");
}

.platform-main-page__background--pl {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/pl.webp");
}

.platform-main-page__background--ru {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/ru.webp");
}

.platform-main-page__background--fr {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/fr.webp");
}

.platform-main-page__background--pt {
  background-image: linear-gradient(
      rgba(75, 87, 136, 0.8),
      rgba(75, 87, 136, 0.8)
    ),
    /* bottom, image */ url("../backgroundImages/pt.webp");
}

.platform-main-page__nav-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
}

.platform-main-page__logo-wrapper {
  padding: 10px 30px;
  background-color: var(--main-color);
  width: fit-content;
}

.platform-main-page__logo {
  height: 50px;
  cursor: pointer;
}

.platform-main-page__main-wrapper {
  display: grid;
  grid-template:
    "a b c" auto
    "d e f" auto;
  grid-gap: 20px;
}

.platform-main-page__course-flag-img {
  position: relative;
  width: 70px;
  margin-bottom: 5px;
  border-radius: 50%;
  border: 2px solid var(--main-color);
  filter: grayscale(50%);

}

.platform-main-page__course-flag-img--active {
  filter: grayscale(0);
}

.platform-main-page__course-flag-img--not-owned {
  filter: grayscale(1);
}

.platform-main-page__course-flag-img::before {
  display: block;
  content: '';
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
}

.platform-main-page__levels-wrapper {
  text-align: center;
  width: 100%;
  max-width: 280px;
  margin: 0 auto;
}

.platform-main-page__level-buttons {
  margin: 20px 0 10px;
  display: flex;
  justify-content: space-between;
}

.platform-main-page__level-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.platform-main-page__level-button-img {
  width: 50px;
  margin-bottom: 5px;
}

.platform-main-page__level-button-img--active {
  width: 65px;
}

.platform-main-page__level-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 15px;
  color: #fff;
  font-weight: 700;
}

.platform-main-page__level-button--active {
  font-size: 2rem;
}

.platform-main-page__grid-element {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 370px;
}

.platform-main-page__grid-bubble {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.platform-main-page__grid-element--learn {
  grid-area: a;
  padding: 10px 0;
  justify-content: space-between;
}

.platform-main-page__welcome-title {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.platform-main-page__congratulations {
  font-size: 16px;
  text-align: center;
  color: #fff;
  font-weight: 400;
}

.platform-main-page__learn-button {
  color: #fff;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75%;
  height: 90px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
}

.platform-main-page__progress-title {
  font-size: 20px;
  font-weight: 600;
}

.platform-main-page__grid-bubble--progress {
  grid-area: b;
  justify-content: space-between;
}

.platform-main-page__grid-bubble--your-courses {
  grid-area: c;
}

.platform-main-page__progress-description {
  text-align: center;
}

.platform-main-page__progress-slogan {
  margin-top: 10px;
  font-size: 13px;
  text-align: center;
}

.platform-main-page__courses-slider-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.platform-main-page__courses-slider-element--clickable {
  cursor: pointer;
}

.platform-main-page__course-flag-description {
  text-align: center;
}

.platform-main-page__courses-slider-wrapper {
  width: 250px;
  position: relative;
}

.platform-main-page__courses-slider-arrow {
  background: none;
  outline: none;
  border: none;
  color: var(--main-color);
  font-size: 35px;
  position: absolute;
  top: calc(50% - 10px);
  transform: translateY(-50%);
}

.platform-main-page__courses-slider-arrow-left {
  left: -30px;
}

.platform-main-page__courses-slider-arrow-right {
  right: -30px;
}

.platform-main-page__courses-title {
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}

.platform-main-page__grid-element--chnage-level {
  grid-area: d;
  padding: 10px 0;
  justify-content: space-around;
}

.platform-main-page__grid-element--activity {
  grid-area: e;
}

.platform-main-page__change-level-title {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.platform-main-page__all-lessons-button {
  display: block;
  font-size: 18px;
  background: var(--main-color);
  color: #fff;
  padding: 15px;
}

.platform-main-page__grid-bubble--activate {
  grid-area: f;
  justify-content: space-between;
}

.platform-main-page__activate-title {
  font-size: 20px;
  color: var(--main-color);
  font-weight: 600;
}

.platform-main-page__activate-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.platform-main-page__activate-input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.platform-main-page__activate-input {
  background: #f6f5f5;
  outline: none;
  border: 1px solid var(--main-color);
  width: 250px;
  height: 35px;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 18px;
}

.platform-main-page__activate-button {
  color: #fff;
  font-size: 16px;
  background-color: var(--main-color);
  padding: 10px 30px;
  border-radius: 5px;
  border: none;
  outline: none;
  cursor: pointer;
}

.platform-main-page__activate-button:disabled {
  background-color: #aaa;
}
.platform-main-page__order-text {
  font-size: 13px;
  margin-bottom: 5px;
}
.platform-main-page__order-link {
  display: flex;
  font-size: 16px;
  background-color: var(--main-color);
  font-weight: 600;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 5px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 1150px) {
  .platform-main-page__main-wrapper {
    display: grid;
    grid-template:
      "a" auto
      "b" auto
      "d" auto
      "e" auto
      "c" auto
      "f" auto;
    grid-gap: 20px;
  }

  .platform-main-page__learn-button {
    margin-top: 10px;
  }

  .platform-main-page__progress-title {
    margin-bottom: 10px;
  }

  .platform-main-page__activate-title {
    margin-bottom: 10px;
  }

  .platform-main-page__grid-element {
    width: 340px;
  }
  
}
