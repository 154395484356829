.flash-message {
    max-width: 300px;
    width: 100%;
    text-align: center;
    position: absolute;
    z-index: 999999999;
    color: var(--white-color);
    background: var(--red-color);
    padding: 5px 10px;
    border-radius: 5px;
    top: 50px;
    left: 50%;
    transform: translate(-50%, -200px);
    opacity: 0;
    transition: all .5s ease;
}

.flash-message--transition {
    opacity: 1;
    transform: translate(-50%, 0);

}

.flash-message__single-error {
    font-size: 1.6rem;
}