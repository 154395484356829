.expression-listening-activity__main-content {
    display: flex;  
    align-items: stretch;
    justify-content: space-around;
    margin-bottom: 50px;
}

.expression-listening-activity__speaker-wrapper {
    justify-self: center;
}

.expression-listening-activity__expressions-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    justify-items: center;
}

.expression-listening-activity__speaker-box  {
    background-color: transparent;
    height: 130px;
    width: 130px;
    border: 2px solid var(--secondary-color);
    border-radius: 5px;
    font-size: 4rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.expression-listening-activity__speaker-button  {
    background-color: transparent;
    border: none;
    border-radius: 5px;
    font-size: 3.5rem;
    cursor: pointer;
    margin: 0 5px;
}

.expression-listening-activity__expressions-wrapper {
    width: 60%;
}

.expression-listening-activity__expression {
    border: 2px solid var(--secondary-color);
    padding: 10px 10px;
    border-radius: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expression-listening-activity__expression:hover {
    cursor: pointer;
}

.expression-listening-activity__expression-text {
    text-align: center;
    font-size: 2rem;
}

.expression-listening-activity__feedback {
    margin-top: 50px;
    margin: 100px auto 0;
    background-color: var(--red-color);
    padding: 5px 20px;
    width: fit-content;
    border-radius: 5px;
    font-size: 2.6rem;
    font-weight: 700;
    color: #fff;
}

.expression-listening-activity__transcription-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
    max-width: 720px;
    margin: 0 auto;
}

.expression-listening-activity__transcription-trigger {
    cursor: pointer;
    width: fit-content;
    color: #666;
}

.expression-listening-activity__transcription-text {
    font-weight: 700;
    opacity: 0;
}

.expression-listening-activity__transcription-text--visible {
    opacity: 1;
}

@media (max-width: 560px) {
    .expression-listening-activity__main-content {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .expression-listening-activity__speaker-box  {
        margin-bottom: 20px;
    }

    .expression-listening-activity__expressions-wrapper {
        grid-template-columns: repeat(1, 1fr);
    }
    
}