.activity-button {
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-family: 'Roboto';
    font-size: 2rem;
    color: #fff;
    display: block;
    margin: 0 auto;
    cursor: pointer;
    background-color: var(--secondary-color);

  }
  